//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-32:_7512,_6092,_1356,_1184,_4180,_4160,_8440,_4044;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-32')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-32', "_7512,_6092,_1356,_1184,_4180,_4160,_8440,_4044");
        }
      }catch (ex) {
        console.error(ex);
      }